import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  background-color: #FDF8EE;
  
  .react-multiple-carousel__arrow {
    svg {
      fill: ${grayscale[500]} !important;
    }
  }
  .react-multi-carousel-dot {
    button {
      background-color: ${grayscale[400]} !important;
    }
  }
  .react-multi-carousel-dot-list {
    bottom: 3px;
  }
  .react-multiple-carousel__arrow--right {
    right: 90px;

    @media ${device.tablet} {
      right: 250px;
    }

    @media ${device.desktopLG} {
      right: 370px;
    }

    @media ${device.desktopXL} {
      right: 460px;
    }
  }
  .react-multiple-carousel__arrow--left {
    left: 90px;

    @media ${device.tablet} {
      left: 250px;
    }

    @media ${device.desktopLG} {
      left: 370px;
    }

    @media ${device.desktopXL} {
      left: 460px;
    }
  }
`

export const CarouselItem = styled.div`
  height: 300px;
  min-width: 280px;
  background-repeat: no-repeat;
  background-size: contain;

  .text {
    @media ${device.desktopXL} {
      line-height: 32px!important;
    }
  }

  @media ${device.tablet} {
    height: 456px;
    min-width: 280px;
  }

  @media ${device.desktopLG} {
    height: 615px;
  }

  @media ${device.desktopXL} {
    height: 600px;
    min-width: 360px;
  }
`
