import React from 'react'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

const CardWrapper = styled.div`
  max-width: 90%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  max-height: 410px;
  height: 100%;
  margin-right: 16px;

  @media ${device.tablet} {
    max-height: 456px;
  }

  @media ${device.desktopLG} {
    max-height: 615px;
  }

  @media ${device.desktopXL} {
    max-width: 90%;
  }
`

const CardImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 50%;
`

const CardBody = styled.div`
  padding: 10px 15px;
  height: 100%;
  background: ${grayscale['500']};

  @media ${device.tablet} {
    padding: 10px 20px;
  }
`

const CardText = styled.p`
  font-size: 16px;
  max-height: 100%;

  .title {
    font-family: "Citrina";
  }

  .date {
    width: 60%;
    font-size: 8px;

    @media ${device.tablet} {
      width: 60%;
      font-size: 12px;
    }

    @media ${device.desktopLG} {
      width: 78%;
      font-size: 18px;
    }

    @media ${device.desktopXL} {
      width: 95%;
    }

    @media ${device.desktopXXXL} {
      width: 67%;
    }
  }

  .address {
    font-size: 8px;

    @media ${device.tablet} {
      font-size: 12px;
    }

    @media ${device.desktopLG} {
      font-size: 18px;
    }
  }
`

const CardCafe = ({ src, alt, title, address, date }: {src: string; alt: string; title: string; address: string; date: string}) => {
  return (
    <CardWrapper>
      <CardImg
        src={src}
        alt={alt}
      />
      <CardBody>
        <CardText className='mt-3'>
          <p className='fs-17 fs-md-24 fs-lg-36 lh-24 lh-md-30 lh-md-50 text-white fw-600 mb-2 title' dangerouslySetInnerHTML={{ __html: title }} />
          <p className='lh-16 lh-md-22 lh-lg-28 text-white text mb-0 mb-md-3 address' dangerouslySetInnerHTML={{ __html: address }} />
          <p className='lh-12 lh-md-12 text-white text m-0 mt-3 mt-md-0 date' dangerouslySetInnerHTML={{ __html: date }} />
        </CardText>
      </CardBody>
    </CardWrapper>
  )
}

export default CardCafe
