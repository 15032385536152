import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/lp-inter-cafe-salad-sm/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #FDF8EE;
  height: 508px;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/lp-inter-cafe-salad-md/image.webp');
    background-position: top right;
    height: 540px;

    a {
      width: 303px;
    }
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/lp-inter-cafe-salad-lg/image.webp');
    background-position: top right;
    height: 736px;

    a {
      width: 390px;
    }
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/lp-inter-cafe-salad-xl/image.webp');
    background-position: top right;
    height: 736px;

    a {
      width: 456px;
    }
  }
`
