import React from 'react'

// Components
import ScrollTo from 'src/components/ScrollTo'
import { Section } from './style'

const Header = () => {
  return (
    <Section className='pb-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-24 fs-md-32 fs-lg-40 fs-xl-48 lh-30 lh-md-40 lh-lg-50 lh-xl-60 text-grayscale--500 fw-500'>
              <span className='fw-600 d-lg-block'>Inter Café.</span> <span className='d-lg-block'>Experimente o</span> sabor de ser Inter
            </h1>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 pr-xl-5 mt-3'>
              <span className='d-xl-block'>Uma pausa agradável no seu dia com comida</span> gostosa e uma grande cartela de cafés
            </p>
            <ScrollTo
              to='#pronto-para-te-receber'
              section='dobra_01'
              sectionName='Inter Café. Experimente o sabor de ser Inter'
              elementName='Saiba mais'
              styles='btn btn-orange--extra btn--lg text-white mw-100 rounded-2 fs-14 fw-600 text-none text-center mt-3 mt-md-1'
            >
              Saiba mais
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Header
