import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import { Wrapper } from './style'

// Sections
import Cafes from './sections/hero/_index'
import ProntoParaTeReceber from './sections/pronto-para-te-receber/_index'
import OutrasUnidades from './sections/outras-unidades/_index'

const Hero = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Cafes />
        <ProntoParaTeReceber />
        <OutrasUnidades />
      </Layout>
    </Wrapper>
  )
}

export default Hero
