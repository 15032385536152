import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import ProntoParaTeReceberJSON from '../../assets/data/ProntoParaTeReceber.json'
import CardCafe from './_Card'

import { Section } from './style'

type ProntoParaTeReceberProps = {
  image: string;
  title: string;
  address: string;
  date: string;
}

const ProntoParaTeReceber = () => {
  return (
    <Section id='pronto-para-te-receber' className='pb-5 pt-md-5 d-flex align-items-end align-items-md-center'>
      <div className='container pt-3 pt-md-0'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='fs-24 fs-md-32 fs-lg-40 fs-xl-48 lh-30 lh-md-40 lh-lg-50 lh-xl-60 text-grayscale--500 text-center fw-600'>
              Pronto para te receber
            </h1>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 text-center mt-3'>
              Descubra a unidade Inter Café mais perto de você
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 90 }}
              md={{ items: 1, partialVisibilityGutter: 250 }}
              lg={{ items: 2, partialVisibilityGutter: 30, slidesToSlide: 1 }}
              xl={{ items: 2, partialVisibilityGutter: 200 }}
            >
              {
                ProntoParaTeReceberJSON.map((item: ProntoParaTeReceberProps) => (
                  <CardCafe src={item.image} alt='' title={item.title} address={item.address} date={item.date} key={item.title} />
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProntoParaTeReceber
